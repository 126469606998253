<template>
  <div class="common-container  home-container">
    <div class="flex-center home-top-wrapper">
      <div class="common-content flex top-wrapper">
        <div class="flex-center logo">
          <img src="~images/home/logo.png" alt="" />
        </div>
        <userInfo></userInfo>
      </div>
    </div>
    <div class="home-wrapper">
      <h3 class="home-title">
        <img src="~images/home/welcome.png" alt="" />
        欢迎使用单桌派
      </h3>
      <div class="flex-center work-box">
        <div class="flex-center go-work" @click="goWork">
          前往工作台
        </div>
      </div>
      <div class="flex-center content-wrapper">
        <div class="flex-center content-main">
          <div
            class="content-main-item"
            v-for="(item, index) in mainList"
            :key="index"
            :style="{ 'margin-top': item.top }"
          >
            <img :src="item.img" alt="" />
            <div class="content-main-name">{{ item.name }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="flex-center main-footer">
      <a href="https://beian.miit.gov.cn" target="block">
        粤ICP备2023025478号
      </a>
    </div>
  </div>
</template>

<script>
import userInfo from "@/components/common/userInfo";
import { getToken } from "@/utils/auth";
export default {
  name: "home",
  components: { userInfo },
  data() {
    return {
      mainList: [
        {
          name: "拥抱变革，迎接未来",
          top: "-70px",
          img: require("images/home/home.jpg")
        },
        {
          name: "精诚所至，金石为开",
          top: "0px",
          img: require("images/home/home1.jpg")
        },
        {
          name: "以人为本，创造美好",
          top: "40px",
          img: require("images/home/home2.jpg")
        },
        {
          name: "持续创新，追求卓越",
          top: "0px",
          img: require("images/home/home3.jpg")
        },
        {
          name: "团结协作，共同成长",
          top: "-70px",
          img: require("images/home/home4.jpg")
        }
      ]
    };
  },
  created() {},
  computed: {},
  activated() {
    if (getToken()) {
      this.$store.dispatch("user/getUserInfo");
      this.$store.dispatch("user/getUserVip");
    }
  },
  mounted() {},
  methods: {
    goHome() {
      this.$router.replace("/");
    },
    goWork() {
      this.$router.replace({
        name: "staging"
      });
    }
  },
  watch: {}
};
</script>
<style lang="stylus" scoped>
.home-container
  overflow hidden
  top 0
  background #e0e4eb

  .logo
    height 100%
    cursor pointer

    img
      width 180px
      height 32px

  .home-top-wrapper
    height 64px
    line-height 64px
    font-size 30px
    font-weight 500
    color #fff
    justify-content space-between
    background #0B2B52

  .top-wrapper
    position relative
    padding-bottom 0

  .home-title
    font-size 48px
    font-weight 700
    text-align center
    padding 50px 0 32px 0
    color #222
    display flex
    align-items center
    justify-content center

    img
      width 64px
      height 64px

  .go-work
    width 200px
    height 57px
    background inherit
    background-color #3478f7
    border none
    border-radius 5px
    -moz-box-shadow none
    -webkit-box-shadow none
    box-shadow none
    font-size 24px
    color #fff
    cursor pointer

  .home-wrapper
    overflow-y auto
    margin 24px
    height calc(100% - 112px)
    background #fff

  .content-wrapper
    margin-top 43px

  .content-main
    width 100%
    padding 0 48px
    justify-content space-between
    align-items flex-start

  .content-main-item
    overflow hidden
    width 336px
    height 450px
    margin-right 24px
    background linear-gradient(360deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%)
    .content-main-name
      padding 12px 0
      font-size 16px
      text-align: center
    &:last-child
      margin-right 0

    img
      width 100%
      min-height calc(100% - 46px)
      border-radius 8px
</style>
